import { PlanDetails, VerifiedPlanDetails } from '@soluto-private/mx-types';

import { PartnerDetails } from '../types';
import { Plan } from '../providers/OneserviceApiClient';

const formatPlanToPlanDetails = (plan: Plan): PlanDetails => ({
    plan: {
        id: plan.agreement.personaId,
        displayName: plan.displayName || plan.agreement.purchase.retailName,
        productType: plan.productType,
        plan: plan.planName,
    },
    agreement: {
        Id: plan.agreement.personaId,
        ClientAccount: {
            ClientChannelId: plan.agreement.clientChannelId,
            Type: plan.agreement.clientAccount.type,
        },
        SubscriptionNumber: plan.agreement.subscriptionNumber,
        Status: plan.agreement.status,
        TerminationDate: plan.agreement.terminationDate,
        Purchase: {
            RetailName: plan.agreement.purchase.retailName,
            RetailSKU: plan.agreement.purchase.retailSku,
            PurchaseDate: plan.agreement.purchase.purchaseDate,
        },
        Customers: plan.agreement.customers?.map((c) => ({
            FirstName: c.firstName,
            LastName: c.lastName,
            Email: c.email,
        })),
    },
});

const formatPlanToVerifiedPlanDetails = (plan: Plan): VerifiedPlanDetails => ({
    ...formatPlanToPlanDetails(plan),
    verifiedThisSession: plan.verifiedThisSession,
});

const formatServices = (plans: Plan[]): string[] =>
    Array.from(
        new Set(
            plans.reduce<string[]>(
                (services, p) => services.concat(p.services),
                []
            )
        )
    );

const formatPlansToPartnerDetails = (plans: Plan[]): PartnerDetails => {
    const activeStatuses = ['ACTV', 'SUSPND'];
    const serviceStatuses = ['ACTV', 'SUSPND', 'TRMNTD'];
    const verifiedPlans = plans.filter((p) => p.verified);
    const unverifiedPlans = plans.filter((p) => !p.verified);
    const planServices = verifiedPlans.filter((p) =>
        serviceStatuses.includes(p.agreement.status)
    );
    const activeVerifiedPlans = verifiedPlans.filter((p) =>
        activeStatuses.includes(p.agreement.status)
    );
    const terminatedPlans = verifiedPlans.filter(
        (p) => p.agreement.status === 'TRMNTD'
    );
    return {
        services: formatServices(planServices),
        plans: activeVerifiedPlans.map(formatPlanToVerifiedPlanDetails),
        unverifiedPlans: unverifiedPlans.map(formatPlanToPlanDetails),
        terminatedPlans: terminatedPlans.map(formatPlanToPlanDetails),
    };
};

export default formatPlansToPartnerDetails;
