import { Key, storage } from '@soluto-private/mx-context';
import { PlanDetails, Subscription } from '@soluto-private/mx-types';

const agreementToSubscription = (
    agreement: PlanDetails['agreement']
): Subscription => ({
    clientChannelId: agreement?.ClientAccount?.ClientChannelId,
    subscriptionNumber: agreement?.SubscriptionNumber,
});

/**
 * Returns a list of subscriptions for the given user.
 * @deprecated Use mx-context-react instead.
 */
export const getUserSubscriptions = async (): Promise<Subscription[]> =>
    await Promise.resolve(
        storage
            .get(Key.VerifiedPlans)
            ?.map(({ agreement }) => agreementToSubscription(agreement))
    );
