import getRedirectUrl from './getRedirectUrl';
import { navigateToUrl } from 'single-spa';

const redirectToPartnerSelection = (filterPartners = false) => {
    const params = new URLSearchParams();
    params.set('redirectUrl', getRedirectUrl());
    params.set('filter', new String(filterPartners).toString());
    navigateToUrl(`/partners/switch?${params.toString()}`);
};

export default redirectToPartnerSelection;
