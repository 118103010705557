const getRedirectUrl = (): string => {
    const url = new URL(window.location.href);
    const { searchParams } = url;

    const redirectUriParamExists = searchParams.has('redirectUrl');
    if (redirectUriParamExists) {
        return searchParams.get('redirectUrl') ?? '';
    }

    const { pathname } = window.location;

    if (pathname.length <= 1) return '';

    const redirectUrl = `${window.location.pathname}${window.location.search}`;
    return redirectUrl;
};

export default getRedirectUrl;
