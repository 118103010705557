import { ContextKey, Key, storage } from '@soluto-private/mx-context';
import { Partner, ProductType } from '@soluto-private/mx-types';

import { PartnerDetails } from '../../types';
import deepEqual from '../../util/deepEqual';

export enum StorageKey {
    Partner = 'mxpartner',
    VerifiedPlans = 'mxverifiedplans',
    UnverifiedPlans = 'mxunverifiedplans',
}

const saveContextKeyIfChanged = <T>(
    key: ContextKey,
    newValue: T,
    validatorFunc?: (val: T) => boolean
): void => {
    const currentValue: T = storage.get<T>(key, validatorFunc);
    if (shouldSaveContext(currentValue, newValue)) {
        storage.set(key, newValue, validatorFunc);
    }
};

const shouldSaveContext = <T>(currentValue: T, newValue: T): boolean => {
    return newValue && !deepEqual(newValue, currentValue);
};

export const persistPartner = (partnerName: Partner) =>
    saveContextKeyIfChanged(Key.Client, partnerName);
export const persistFilteredPartners = (partners: Partner[]) =>
    saveContextKeyIfChanged(Key.FilteredPartners, partners);
export const persistProductType = (productType: ProductType) =>
    saveContextKeyIfChanged(Key.ProductType, productType);

export const persistPartnerDetails = ({
    services,
    plans,
    unverifiedPlans,
    terminatedPlans,
}: PartnerDetails): void => {
    saveContextKeyIfChanged(Key.Services, services);
    saveContextKeyIfChanged(
        Key.Plans,
        plans.map(({ plan }) => plan)
    );
    saveContextKeyIfChanged(Key.VerifiedPlans, plans);
    saveContextKeyIfChanged(Key.UnverifiedPlans, unverifiedPlans);
    saveContextKeyIfChanged(Key.TerminatedPlans, terminatedPlans);
};

export const clearContext = (): void => {
    storage.remove(Key.Client);
    storage.remove(Key.FilteredPartners);
    storage.remove(Key.Services);
    storage.remove(Key.Plans);
    storage.remove(Key.VerifiedPlans);
    storage.remove(Key.UnverifiedPlans);
    storage.remove(Key.TerminatedPlans);
};

export const clearPlansAndServices = (): void => {
    storage.remove(Key.Services);
    storage.remove(Key.Plans);
    storage.remove(Key.VerifiedPlans);
    storage.remove(Key.UnverifiedPlans);
    storage.remove(Key.TerminatedPlans);
};
