import { Provider, createProvider } from '@soluto-private/mx-config';

import { Partner } from '@soluto-private/mx-types';
import { monitor } from '@soluto-private/mx-monitor';

const canUseDefaultProductType = async (partner: Partner) => {
    const provider = createProvider(Provider.OneServiceApi);

    try {
        const configuration = await provider.getPartnerProductConfiguration(
            partner
        );
        return (
            !!configuration && !configuration.partnerPicker.requireProductType
        );
    } catch (ex) {
        const error =
            ex instanceof Error ? ex : new Error('Something went wrong');
        monitor.error('Error fetching configuration', error, { partner });
        return false;
    }
};

export default canUseDefaultProductType;
