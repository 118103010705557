import { Partner, ProductType } from '@soluto-private/mx-types';

const ASURION_UI_CDN = 'https://assets.asurion-ui.my.asurion53.com';

const formattedProductTypes: Partial<Record<ProductType, string>> = {
    [ProductType.Appliance]: 'appliance-plus',
    [ProductType.General]: 'complete-my-home',
    [ProductType.Home]: 'home-plus',
    [ProductType.Mobility]: 'phone-care',
};

const partnerLogoModifiers: Partial<
    Record<Partner, Partial<Record<ProductType, string>>>
> = {
    [Partner.Asurion]: {
        [ProductType.Appliance]: 'stacked',
        [ProductType.General]: 'stacked',
        [ProductType.Home]: 'stacked',
        [ProductType.Mobility]: 'stacked',
    },
};

const ignoreStandalonePartners: Partner[] = [Partner.Asurion];

export const generateLogoSrc = (
    partner: Partner,
    productType: ProductType = ProductType.Default
) => {
    const formattedProductType = formattedProductTypes[productType];
    const partnerProductName = `${partner}${
        formattedProductType ? `-${formattedProductType}` : ''
    }`;

    const logoModifier = partnerLogoModifiers[partner]?.[productType];
    const standalone = !ignoreStandalonePartners.find((p) => p === partner);
    const filename = `${partnerProductName}-logo${
        logoModifier ? `-${logoModifier}` : ''
    }${standalone ? '-standalone' : ''}.svg`;

    return new URL(`/images/logos/${filename}`, ASURION_UI_CDN).toString();
};
