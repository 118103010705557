import { ClientError, GraphQLClient, gql } from 'graphql-request';
import { Partner, ProductType } from '@soluto-private/mx-types';
import {
    getAccessToken,
    getUser,
    redirectToLogin,
} from '@soluto-private/mx-app-authentication';

import env from './env';
import { monitor } from '@soluto-private/mx-monitor';
import { v4 } from 'uuid';

export type Purchase = {
    purchaseDate: string;
    retailName: string;
    retailSku: string;
};

export type Customer = {
    firstName: string;
    lastName: string;
    email?: string;
};

export type ClientAccount = {
    type: string;
};

export type Agreement = {
    clientChannelId: string;
    personaId: string;
    status: string;
    subscriptionNumber: string;
    purchase: Purchase;
    clientAccount: ClientAccount;
    customers?: Customer[];
    terminationDate?: string;
};

export type Plan = {
    agreement: Agreement;
    displayName: string;
    planName: string;
    partner: Partner;
    productType: ProductType;
    services: string[];
    verified: boolean;
    verifiedThisSession: boolean;
};

export type GetPlansResponse = {
    getPlans: Plan[];
};

const queryGetPlans = gql`
    query GetPlans {
        getPlans {
            agreement {
                clientChannelId
                personaId
                status
                subscriptionNumber
                purchase {
                    purchaseDate
                    retailName
                    retailSku
                }
                customers {
                    firstName
                    lastName
                    email
                }
                terminationDate
                clientAccount {
                    type
                }
            }
            displayName
            planName
            partner
            productType
            services
            verified
            verifiedThisSession
        }
    }
`;

export class OneserviceApiClient {
    private readonly client: GraphQLClient;

    constructor(private url: string = env.oneserviceApiUrl) {
        this.client = new GraphQLClient(this.url, {
            errorPolicy: 'all',
            headers: () => ({ authorization: `${getAccessToken()}` }),
            jsonSerializer: {
                stringify: JSON.stringify,
                parse: (obj): unknown =>
                    JSON.parse(obj, (_, value: unknown) => {
                        // Remove null values from api response
                        if (value === null) {
                            return;
                        } else if (Array.isArray(value)) {
                            return value.filter((e) => e !== null) as unknown;
                        } else {
                            return value;
                        }
                    }),
            },
        });
    }

    private async handleUnauthorized(response: unknown) {
        if (
            response instanceof ClientError &&
            response.response.status === 401
        ) {
            await redirectToLogin();
        }
    }

    private async monitorError(error: Error, message?: string) {
        const user = await getUser();
        monitor.error(message ?? error.message, error, {
            asurionId: user?.profile.asurion_id,
        });
    }

    private async request<T>(query: string): Promise<T> {
        // Mimic this.client.request but monitoring errors instead of throwing them,
        // allows alerting on errors / invalid plans while still returning valid plans.
        try {
            const request = {
                query,
                requestHeaders: [['x-correlation-id', v4()]],
            };

            const result = await this.client.rawRequest<T>(request);
            if (Array.isArray(result.errors) && result.errors.length) {
                const error = new ClientError({ ...result }, { query });
                await this.monitorError(error);
            }

            return result.data;
        } catch (error) {
            await this.handleUnauthorized(error);
            throw error;
        }
    }

    public async getPlans(): Promise<Plan[]> {
        try {
            const data = await this.request<GetPlansResponse>(queryGetPlans);
            return data.getPlans;
        } catch (ex) {
            const error =
                ex instanceof Error ? ex : new Error('Something went wrong');
            await this.monitorError(error, 'Failed to fetch plans!');
            return [];
        }
    }
}
