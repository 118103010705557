import { Partner } from '@soluto-private/mx-types';
import { dispatchAnalytics } from '../providers/analyticsDispatcher';
import getRedirectUrl from './getRedirectUrl';
import { stepUpByPartner } from '@soluto-private/mx-app-authentication';

const SKIP_VERIFICATION_PATHS = [
    '/security',
    '/gallery',
    '/demo',
    '/replacement',
];

const SKIP_VERIFICATION_PARTNERS = [Partner.DirecTV];

const canSkipVerification = (
    partner: Partner,
    redirectUrl: string
): boolean => {
    const basePathName = `/${window.location.pathname.split('/')[1]}`;

    return (
        SKIP_VERIFICATION_PARTNERS.includes(partner) ||
        SKIP_VERIFICATION_PATHS.includes(redirectUrl) ||
        SKIP_VERIFICATION_PATHS.includes(basePathName)
    );
};

const redirectToAsurionIdVerification = async (
    partner: Partner,
    redirectUrl: string
) =>
    await stepUpByPartner({
        state: {
            redirectUri: new URL(redirectUrl, window.location.href).toString(),
        },
        partner,
    });

const redirectToPartnerVerification = async (
    partner: Partner,
    redirectUrl: string = getRedirectUrl()
) => {
    const skipVerification = canSkipVerification(partner, redirectUrl);
    if (!skipVerification) {
        dispatchAnalytics('OneService_PersonaVerificationRedirect', {
            partner,
            verificationFlow: 'step-up-authentication',
        });
        await redirectToAsurionIdVerification(partner, redirectUrl);
    }
};

export default redirectToPartnerVerification;
